import { useState } from "react";
import TextField from "@mui/material/TextField";
import "./OrdersCancel.scss";
import MySnack from "../common/MySnack";
import { cancelDelivery } from "store/deliveries";
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker"
import InputIcon from "react-multi-date-picker/components/input_icon"
import "react-multi-date-picker/styles/layouts/mobile.css"
export default function OrdersCancel(props) {
  const [spinner2, toggleSpinner2] = useState(true);
  const [alertmessage, setalertmessage] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const [dates, setDates] = useState([

  ]);

  const cancelOrder = (e) => {

    let fromDateIs = fromDate
    let toIs = toDate

    defaultDate.setDate(defaultDate.getDate() + 1);
    if (fromDate == null) {
      fromDateIs = defaultDate.toLocaleDateString("fr-CA")
    }
    if (toDate == null) {
      toIs = defaultDate.toLocaleDateString("fr-CA")
    }




    e.preventDefault();
    let data = {
      delivery_id: props?.order,
      from: fromDateIs, // "1632009660000",
      to: toIs, // "1632009590000",
    };

    cancelDelivery(props?.access_token, data).then((res) => {
      toggleSpinner2(false);
      setalertmessage("Successfully cancled this delivery ");
    })
      .catch((res) => {
        if (res && res.response && res.response.status === 401) {
          toggleSpinner2(true);
          setalertmessage("Refreshing");
        }
        toggleSpinner2(false);
        setalertmessage("Unable to cancel the delivery. ");
      });
  };


  const revertCancelOrder = (e) => {
    e.preventDefault();
    let data = {
      delivery_id: props?.order,
      revertCancelDelivery: 1
    };

    cancelDelivery(props?.access_token, data).then((res) => {
      toggleSpinner2(false);
      setalertmessage("Successfully cancled this delivery ");
    })
      .catch((res) => {
        if (res && res.response && res.response.status === 401) {
          toggleSpinner2(true);
          setalertmessage("Refreshing");
        }
        toggleSpinner2(false);
        setalertmessage("Unable to cancel the delivery. ");
      });
  };

  function handleDateChange(dates) {
    
    let backDate = false;
    dates.forEach(element => {
      
      if (new Date(element) < new Date()) {
        backDate = true
      }
    });
    if (backDate == false)
      setDates(dates)
    else {
      setDates([])
      alert("Cannot select less than current date. ");

    }

  }
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + 1);
  return (
    <>

      <div className="OrdersCancel__main-container">
        <div>
          <div className="OrdersCancel__row-class" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <DatePicker className="rmdp-mobile"
              value={dates}
              dayPickerProps={{ disabledDays: { before: new Date() } }}
              mapDays={({ date }) => {
                let mapDate = new Date()
                mapDate.setDate(date.day)
                mapDate.setMonth(date.month-1)
                let color
                const arrayDates = props?.orderDetails[0]?.CancelDeliveries?.map((eachElement) => {
                  return new Date(eachElement.cancel_date)
                });

                let existDate=false;
                existDate= arrayDates?.find((eachDate) => mapDate.getDate() == new Date(eachDate).getDate() && mapDate.getMonth() == new Date(eachDate).getMonth())
                
                if (existDate && mapDate.getTime() < new Date().getTime())
                  color = "red"
                else if (existDate && mapDate.getTime() > new Date().getTime())
                  color = "blue"
                else if(existDate==undefined && mapDate.getTime() < new Date().getTime())
                  color = "teal"
                if (color) return {style: { backgroundColor: color } ,className: "highlight highlight-" + color }
              }}
              showOtherDays
              minDate={new Date().setMonth(new Date().getMonth() - 3)}
              maxDate={new Date().setDate(new Date().getDate() + 20)}
              multiple
              onChange={date => handleDateChange(date)}
              render={<InputIcon />}
              showOtherDays={false}
              plugins={[
                <DatePickerHeader position="top" size="medium" />,
                <DatePanel position={"right"}
                  sort="date" />
              ]}
            />
            {/* <TextField
              id="frdate"
              label="From Date"
              type="date"
              InputProps={{ inputProps: { min: defaultDate.toLocaleDateString("fr-CA") } }}
              defaultValue={defaultDate.toLocaleDateString("fr-CA")}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="todate"
              label="To Date"
              type="date"
              InputProps={{ inputProps: { min: defaultDate.toLocaleDateString("fr-CA") } }}
              defaultValue={defaultDate.toLocaleDateString("fr-CA")}
              onChange={(newValue) => {
                console.log(newValue.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }} */}
            {/* /> */}
          </div>
        </div>
        <div className="OrdersCancel__row-class">
          {/* {!spinner ? () : ()} */}
          <div onClick={(e) => cancelOrder(e)} className="OrdersCancel__edit">
            Cancel
          </div>

          <div onClick={(e) => revertCancelOrder(e)} className="OrdersCancel__revertedit">
            Revert cancel
          </div>

        </div>


        {alertmessage && <MySnack message={alertmessage} />}
      </div>
    </>
  );
}
