import { useState, useEffect, useContext } from "react";
import UserContext from "UserContext";
import { ReactComponent as CartIcon } from "svgs/cartIcon.svg";
import { listAgentDelivery, listDeliveryTypes } from "store/deliveries";
import ListAgentDeliveries from "components/Deliveries/ListAgentDeliveries";
import Spinner from "components/common/Spinner";
import Skeleton from "@mui/material/Skeleton";
import "../Orders/Orders.scss";
import "./Deliveries.scss";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";

export default function Deliveries() {
  const userInfo = useContext(UserContext);
  const [deliveries, setDeliveries] = useState([]);
  const [constDeliveries, setConstDeliveries] = useState([]);
  const { access_token, userDetails, refreshAccessToken } = userInfo;
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [spinner, toggleSpinner] = useState(false);
  
  useEffect(() => {
    toggleSpinner(true);
    listAgentDelivery(userDetails.user_id, access_token)
      .then((res) => {
        setConstDeliveries(res.data.data)
        setDeliveries(res.data.data);
      })
      .catch((res) => {
        toggleSpinner(false);
        if (res && res.response && res.response.status === 401) {
          refreshAccessToken();
        }
      });
    listDeliveryTypes(access_token)
      .then((res) => {
        setDeliveryTypes(res.data.data);
        toggleSpinner(false);
      })
      .catch((res) => {
        toggleSpinner(true);
      });
  }, [userDetails.user_id, access_token, refreshAccessToken]);
  let inputHandler = (e) => {
    //convert input text to lower case
    
    var lowerCase = e.target.value.toLowerCase();
     let sortedOrder;
     sortedOrder = filterIt(constDeliveries,lowerCase)
     if(sortedOrder.length>0)
     setDeliveries(sortedOrder);
  };

  function filterIt(arr, searchKey) {
    return arr.filter(function(obj) {
      return Object.keys(obj.UserDetail).some(function(key) {
      if(key==='phone_no'||key==='address')
        return obj.UserDetail[key].toLowerCase().includes(searchKey);
      })
    });
  }
  return (
    <div className="main-container">
      <div className="Orders__main-heading" >
        <div className="General-main-heading">
          <CartIcon /> {"  "} Agent Deliveries
        </div>
        <div className="__searchorders" style={{margin:"auto",width: "50%"}}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={inputHandler}
            fullWidth
            label="Search orders"
          />
        </div>
      </div>
      <div>
        {spinner && !deliveries.length ? (
          <>
            <Spinner />
            <Skeleton animation="wave" height={100} width="80%" />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={210}
              height={118}
            />
          </>
        ) : (
          <ListAgentDeliveries
            agent={userInfo.userDetails.name}
            userDetails={userInfo.userDetails}
            // refreshdata={refreshdata}
            deliveryTypes={deliveryTypes}
            deliveries={deliveries}
          />
        )}
      </div>
    </div>
  );
}
