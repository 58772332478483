import { useRef, useEffect, useState, useMemo, useCallback } from "react";

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import '../../styles/style.css'

export default function HistoryTable(props) {
  
  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      width: 100,
    },
    {
      field: "user_name",
      headerName: "Agent Name",
      width: 100,
    },
    // {
    //   field: "agent_name",
    //   headerName: "Agent name",
    //   width: 200,
    // },
    {
      field: "product",
      headerName: "Product",
      width: 100,
    },
    {
      field: "delivery_type",
      headerName: "Delivery Type",
      description: "Delivery Type.",
      width: 100,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      description: "Delivery Type.",
      width: 100,
    },
    {
      field: "delivered_at",
      headerName: "Delivered",
      description: "Delivery Time.",
      width: 100,
    },
    {
      field: "price",
      headerName: "Price",
      description: "Price.",
      width: 100
    },
    {
      field: "total",
      headerName: "Total",
      description: "Total",
      width: 100,
      //aggFunc: 'sum'
    },

  ];



  const columnsAgent = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      width: 100,
    },
    {
      field: "user_name",
      headerName: "User Name",
      width: 100,
    },
    // {
    //   field: "agent_name",
    //   headerName: "Agent name",
    //   width: 200,
    // },
    {
      field: "product",
      headerName: "Product",
      width: 100,
    },
    {
      field: "delivery_type",
      headerName: "Delivery Type",
      description: "Delivery Type.",
      width: 100,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      description: "Delivery Type.",
      width: 100,
    },
    {
      field: "delivered_at",
      headerName: "Delivered",
      description: "Delivery Time.",
      width: 100,
    },
    {
      field: "address",
      headerName: "Address",
      description: "Address.",
      width: 100
    }
  ];

  const [rowsAre, setRows] = useState(props?.historyData)
  const nodeRef = useRef();
  const [coloumDefs] = useState(props?.fromPage==="1"? columnsAgent:columns )

  const callingExport = () => {
    if(props?.fromPage==="1"){

      nodeRef?.current?.api?.exportDataAsExcel({ fileName: `${props?.historyData[0]?.agent_name}.xlsx` })
    }
    else{
      nodeRef?.current?.api?.exportDataAsExcel({ fileName: `${props?.historyData[0]?.address}.xlsx` })
    }
    
  }
  const pinnedBottomRowData = useMemo(() => {
    const createData = () => {
      var result = [];
      if(props?.fromPage!=="1"){
      result.push({
        price: 'Total',
        total: '0',
      });
    }

      return result;
    };
    return createData(1, "", '');
  }, []);

  useEffect(async () => {
    if(props?.fromPage!=="1"){
    props?.historyData.forEach((item, index) => {
      props.historyData[index].total = Number(item.quantity) * Number(item.price)
    });
  }


  });



  useEffect(() => {

    // props.historyData.forEach((item, index) => {
    //     setTotalPrice((prev) => parseInt(prev) + parseInt(item.total));
    //   });

    let calculateTotal = (props?.fromPage!=="1"||props?.fromPage===undefined ) ?props?.historyData.reduce((a, c) => a + Number(c.total), 0):"" 

    
    if (props?.historyData?.length > 0) {
      // nodeRef.current.api.refreshCells()

      // {
      //   delivered_at: "",
      //   delivery_type: "",
      //   id: "",
      //   price: "Total",
      //   product: "",
      //   quantity: "",
      //   user_name: "",
      // total: calculateTotal
      // }
      //setTotalPrice(calculateTotal)


      console.log(`Uncomment this line `)
      if(props?.fromPage!=="1"||props?.fromPage===undefined  ){
      nodeRef?.current?.api?.setPinnedBottomRowData([{
        price: 'Total',
        total: " " + calculateTotal,
      }])
    }
      setRows([...props?.historyData||[]])
      //  nodeRef.current.api.refreshCells()
    }

    // setRows([...props.historyData])

  }, [props?.historyData||[]])



  const defaultColDef = useMemo(() => {
    return {
      width: 200,
      // sortable: true,
      // filter: true,
      resizable: true,
    };
  }, []);
  const getRowStyle = useCallback(function (params) {
    if (params.node.rowPinned) {
      return { 'fontWeight': 'bold' };
    }
  }, []);



  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "95%" }}>

      <button onClick={callingExport}> Export to Excel</button>

      <AgGridReact ref={nodeRef}
        rowData={rowsAre}
        columnDefs={coloumDefs}
        rowStyle={{ textAlign: 'center' }}
        onGridReady={(event) => {
          event.api.sizeColumnsToFit();
          event.columnApi.resetColumnState();
        }}
        pinnedBottomRowData={pinnedBottomRowData}
        // groupIncludeFooter={true}
        // groupIncludeTotalFooter={true}
        // animateRows={true}
        defaultColDef={defaultColDef}
        getRowStyle={getRowStyle}
        domLayout={'autoHeight'}
        pivotPanelShow={'always'}
        enableRangeSelection={true}
        pagination={true}
      //  autoGroupColumnDef={autoGroupColumnDef}
      >

      </AgGridReact>


    </div>

  );
}
