import axiosInstance from "axiosConfig";

export const listPaymentTypes = (access_token) => {
  return axiosInstance({
    method: "GET",
    url: "payments/listpaymentmodes",
    headers: { access_token: access_token },
  });
};



export const updatePaymentTypes = (access_token, senddata, id) => {
  return axiosInstance({
    method: "POST",
    url: `payments/${id}/updatepaymenttype`,
    headers: { access_token: access_token },
    data: senddata,
  });
};

export const addPaymentTypes = (access_token, data) => {
  return axiosInstance({
    method: "POST",
    url: "payments/addpaymenttype",
    data: data,
    headers: { access_token: access_token },
  });
};