import { getHistory } from "store/deliveries";
import { useContext, useState,useEffect } from "react";
import { useQuery } from "react-query";

import UserContext from "UserContext";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import HistoryTable from "components/common/HistoryTable";
import "components/Users/Users.scss";
import MySnack from "components/common/MySnack";
import Skeleton from "@mui/material/Skeleton";
import Spinner from "components/common/Spinner";
import EditAgent from 'components/Users/_id/EditAgent'
import { listUsers } from "store/user";
export default function UsersHistory() {
  let userInfo = useContext(UserContext);
  let { access_token, refreshAccessToken } = userInfo;
  const [spinner, toggleSpinner] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [fromSelectedDate, setFromDate] = useState(new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString());
  const [alertmessage, setalertmessage] = useState(null);
  const [toSelectedDate, setToDate] = useState(new Date().toISOString());
  const [curr_id, setCurrid] = useState(null);
  const [currUser, setCurrUser] = useState();
  useEffect(() => {
    console.log("user effect details are ",window.location.pathname.split("/")[2]);
    setCurrid(window.location.pathname.split("/")[2]);
  }, [curr_id, access_token, refreshAccessToken]);

  useQuery([2, userInfo.access_token], listUsers, {
    retry: 1,
    onSuccess: (data) => {
      console.log("agent details are ",JSON.stringify(data.data));
      data?.data && setCurrUser(data.data.data.find((i) => i.user_id === curr_id));
      setTimeout(() => {
        toggleSpinner(false);
      }, 2000);
    },
    onError: (error) => {
      console.log("error in getting agent detail;s are ",error.message);
      if (error && error.response && error.response.status === 401) {
        toggleSpinner(true);
        refreshAccessToken();
      }
    },
  });


  const getCustomerData = (e) => {
    setHistoryData([]);
    toggleSpinner(true);
    e.preventDefault();
    let curr_id = window.location.pathname.split("/")[2];
    let data = {
      agent_id: [curr_id],
      from: fromSelectedDate,
      to: toSelectedDate,
    };
    if (curr_id) {
      getHistory(access_token, data)
        .then((res) => {
          modifyHistoryData(res.data.data);
          toggleSpinner(false);
          setalertmessage("Data Fetched");
        })
        .catch((res) => {
          if (res && res.response && res.response.status === 401) {
            toggleSpinner(true);
            setalertmessage("Refreshing");
            userInfo.refreshAccessToken();
          }
          toggleSpinner(false);
          setalertmessage("No Data Available for Dates ");
        });
    }
  };

  const modifyHistoryData = (hist) => {
    // setHistoryData(hist);
    hist.forEach((item, index) => {
      let abc = {
        id: index + 1,
        user_name: item.UserDetail.name,
        // agent_name: item.UserDetail.name,
        delivery_type: item.DeliveryType.name,
        product: item.Product.name,
        quantity: item.quantity,
        price: item.price,
        address:item?.UserDetail?.address,
        delivered_at: new Date(item.delivered_at).toLocaleString("en-Gb"),
      };
      setHistoryData((prevData) => [...prevData, abc]);
    });
  };
  const captureFromDate = (e) => {
    e.preventDefault();
    let fromDate = new Date(e.target.value).toISOString();
    setFromDate(fromDate);
  };

  const captureToDate = (e) => {
    e.preventDefault();
    // let a = e.target.setHours(23, 59, 00, 00);
    let toDate = new Date(e.target.value).toISOString();
    setToDate(toDate);
  };

  return (
    <div className="main-container">
      {!spinner ? (
        <>
          <EditAgent access_token={access_token} currUser={currUser}></EditAgent>
          <Paper className="Users__top">
            Agent History Datassddd
            <div className="Users__fromdate">
              <TextField
                id="fromdate"
                label="From Date"
                type="date"
                onChange={(e) => captureFromDate(e)}
                defaultValue={new Date().toLocaleDateString("fr-CA")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="Users__fromdate">
              <TextField
                id="todate"
                label="To Date"
                type="date"
                onChange={(e) => captureToDate(e)}
                defaultValue={new Date().toLocaleDateString("fr-CA")}
                InputLabelProps={{
                  shrink: true,
                  max: new Date().toLocaleDateString("fr-CA"),
                }}
              />
            </div>
            <button className="Users__refresh-button" onClick={(e) => getCustomerData(e)}>
              Submit
            </button>{" "}
          </Paper>
          <Paper>
            <HistoryTable historyData={historyData} fromPage="1" />
          </Paper>
        </>
      ) : (
        <div className="Agents__spinners">
          <Spinner />
          <Skeleton animation="wave" height={100} width="80%" />
          <Skeleton variant="rectangular" animation="wave" width={210} height={118} />
        </div>
      )}
      {alertmessage && <MySnack message={alertmessage} />}
    </div>
  );
}
